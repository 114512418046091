import React from "react";

const ControlledTextInput = ({ searchValue, handleSearchInputChange, placeholder }) => {
  return (
    <input
      type="text"
      value={searchValue}
      placeholder={placeholder}
      onChange={e => handleSearchInputChange(e.target.value)}
    />
  );
}

export default ControlledTextInput;