import React from "react";
import './styles.scss';
import {
  IMAGE_TYPES,
  DEFAULT_IMAGE_TYPE,
  RASTER_FILE_FORMATS,
  DEFAULT_RASTER_FILE_FORMAT,
  RASTER_MIME_TYPES,
  VECTOR_FILE_FORMATS,
  DEFAULT_VECTOR_FILE_FORMAT
} from "./constants";
import { getFileName } from "./helpers";
import ImageResolutionPicker from "../ImageResolutionPicker";
import ImageFormatSelect from "../ImageFormatSelect";

class ImageResizer extends React.Component {
  constructor() {
    super();

    this.state = {
      // setting width and height to suppress console errors
      width: 0,
      height: 0,
      type: DEFAULT_IMAGE_TYPE,
      format: DEFAULT_RASTER_FILE_FORMAT,
    };
  }

  componentDidMount() {
    var img = new Image();
    img.onload = () => {
      this.setState({
        width: img.width,
        height: img.height,
        maxWidth: img.width,
        maxHeight: img.height,
        aspectRatio: img.width / img.height,
      });
    }
    img.src = this.props.selectedImagePath + DEFAULT_RASTER_FILE_FORMAT;

    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("click", this.handleMouseClick);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("click", this.handleMouseClick);
  }

  handleMouseClick = (e) => {
    const { handleCloseButton } = this.props;
    if (["ImageResizer", "closeButton"].includes(e.target.className)) {
      handleCloseButton(e);
    }
  }

  handleKeyDown = (e) => {
    const { handleCloseButton } = this.props;
    if (e.key === "Escape") {
      handleCloseButton(e);
    }
  }

  handleResolutionChange = (value, dimension) => {
    const { maxWidth, aspectRatio } = this.state;

    let newWidth, newHeight;
    if (dimension === 'width') {
      newWidth = Number(value);
      newWidth = Math.min(maxWidth, value);
    } else {
      newHeight = Number(value);
      newWidth = Math.round(newHeight * aspectRatio);
      newWidth = Math.min(maxWidth, value);
    }

    this.setState({
      width: newWidth,
      height: Math.round(newWidth / aspectRatio),
    });
  }

  handleTypeChange = (e) => {
    const newType = e.target.innerHTML.toLowerCase();
    this.setState({ type: newType });

    newType === "raster" ?
      this.setState({ format: DEFAULT_RASTER_FILE_FORMAT }) :
      this.setState({ format: DEFAULT_VECTOR_FILE_FORMAT });
  }

  handleFileFormatChange = (e) => {
    this.setState({ format: e.target.value.toLowerCase() });
  }

  download = (fileName, href) => {
    var link = document.createElement("a");
    link.download = fileName;
    link.href = href;
    link.click();
    link.remove();
  }

  handleDownloadClick = (e) => {
    e.preventDefault();
    const { format: fileFormat, type, width, height } = this.state;
    const { selectedImagePath } = this.props;

    const fileName = getFileName(selectedImagePath, fileFormat);
    const filePath = selectedImagePath + fileFormat;

    if (type === "raster") {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        ctx.canvas.toBlob((blob) => {
          this.download(fileName, URL.createObjectURL(blob));
        }, RASTER_MIME_TYPES[fileFormat.replace(".", "")], 1);

        canvas.remove();
      };
      img.src = filePath;
    } else {
      this.download(fileName, filePath);
    }
  }

  render() {
    const { handleCloseButton, selectedImagePath, selectedImageName } = this.props;
    const { type, format, width, height } = this.state;

    return (
      <div className="ImageResizer">
        <div className="container">

          <span className="closeButton icon icon-large icon-close-thin" onClick={handleCloseButton} />

          <div className="image-container">
            <img src={selectedImagePath + DEFAULT_RASTER_FILE_FORMAT} alt="selected" />
          </div>

          <div className="download-options">
            <div className="title">
              {selectedImageName}
            </div>

            <div className="button-group">
              {IMAGE_TYPES.map(imgType => <button key={imgType.value} className={type === imgType.value ? "active" : ""} onClick={this.handleTypeChange}>{imgType.label}</button>)}
            </div>

            <label>Choose file format</label>
            <ImageFormatSelect
              value={format}
              type={type}
              handleChange={this.handleFileFormatChange}
              rasterFileFormats={RASTER_FILE_FORMATS}
              vectorFileFormats={VECTOR_FILE_FORMATS}
            />

            {type === "raster" &&
              <>
                <label>Desired dimensions (in px)</label>
                <ImageResolutionPicker
                  width={width}
                  height={height}
                  handleResolutionChange={this.handleResolutionChange}
                />
              </>
            }

            <button type="submit" onClick={this.handleDownloadClick}>Download image</button>
          </div>
        </div>
      </div>
    );
  }
}

export default ImageResizer;