import React from "react";
import "./styles.scss";

const ImageFormatSelect = ({ value, type, handleChange, rasterFileFormats, vectorFileFormats, ...rest }) => {
  return (
    <div className="ImageFormatSelect">
      <select value={value} onChange={handleChange}>
        {type === "raster" ?
          rasterFileFormats.map(format => <option key={format.value} value={format.value}>{format.label}</option>)
          :
          vectorFileFormats.map(format => <option key={format.value} value={format.value}>{format.label}</option>)
        }
      </select>
    </div>
  );
}

export default ImageFormatSelect;