import React from "react";

const ButtonClose = ({searchValue, onClick}) => {
  return (
    <>
    {searchValue && 
      <span 
        className="icon icon-small icon-close"
        onClick={onClick}
      />
    }
    </>
  );
}

export default ButtonClose;