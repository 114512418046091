import React from "react";
import "./styles.scss"

const ImageResolutionPicker = ({ width, height, handleResolutionChange }) => {
  return (
    <div className="ImageResolutionPicker">
      <input 
        type="number" 
        placeholder="width"
        value={width}
        onChange={e => handleResolutionChange(e.target.value, "width")}
      />
      <span className="icon-small icon-close"/>
      <input 
        type="number" 
        placeholder="height"
        value={height}
        onChange={e => handleResolutionChange(e.target.value, "height")}
      />
    </div>
  );
}

export default ImageResolutionPicker;