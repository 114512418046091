function isArrayInArray(item, arr){
  return item.every(elem => arr.indexOf(elem) > -1)
}

function filterResults(structure, searchValue) {
  if (!searchValue) {
    return structure;
  }
  const searchItems = searchValue.trim().toLowerCase().split(" ");

  let filteredStructure = [];

  structure.forEach(element => {
    if (isArrayInArray(searchItems, element.keywords)) {
      filteredStructure.push(element);
    }
  });
  return filteredStructure;
}

export default filterResults;