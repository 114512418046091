import React from "react";
import './styles.scss';
import { GALLERY_PATH } from "../../constants";

class SearchResults extends React.Component {
  createItems(results) {
    const items = results.map(image =>
      <div
        className="search-result-container"
        key={image.filename}
        onClick={e => this.props.handleImageClick(GALLERY_PATH + image.filename, image.name)}
      >
        <div className="image-container">
          <img
            src={GALLERY_PATH + image.filename + ".jpg"}
            className="icon"
            alt="search result"
          />
        </div>

        <p>{image.name}</p>
      </div>
    );
    return (
      <>
        {items}
      </>
    )
  }

  render() {
    const { results } = this.props;

    return (
      <div className="SearchResults">
        {results && this.createItems(results)}
        {results.length === 0 && <p className="no-results">No images found.</p> }
      </div>
    );
  }
}

export default SearchResults;
