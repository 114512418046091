import React from 'react';
import './App.scss';
import SearchBar from "./components/SearchBar";
import SearchResults from "./components/SearchResults";
import ImageResizer from "./components/ImageResizer";
import structure from './data/structure.json';
import filterResults from "./helper";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedImagePath: undefined,
      selectedImageName: undefined,
      searchValue: ""
    }
  }

  handleSearchInputSubmit = (newValue) => {
    this.setState({ searchValue: newValue });
  }

  handleImageClick = (image, name) => {
    this.setState({ 
      selectedImagePath: image,
      selectedImageName: name
     });
  }

  handleCloseButton = (e) => {
    this.setState({ selectedImagePath: undefined });
  }

  render() {
    const { selectedImagePath, selectedImageName, searchValue } = this.state;
    const filteredResults = filterResults(structure, searchValue);

    return (
      <div className="App">
        <SearchBar
          handleSearchInputSubmit={this.handleSearchInputSubmit}
        />

        <SearchResults
          results={filteredResults}
          handleImageClick={this.handleImageClick}
        />

        {selectedImagePath &&
          <ImageResizer
            selectedImagePath={selectedImagePath}
            selectedImageName={selectedImageName}
            handleCloseButton={this.handleCloseButton}
          />
        }
      </div>
    );
  }
}

export default App;
