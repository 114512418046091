import React from "react";
import './styles.scss';
import search_icon from './search.svg';
import ButtonClose from "../ButtonClose";
import ControlledTextInput from "../ControlledTextInput";

class SearchBar extends React.Component {
  constructor() {
    super();

    this.state = {
      searchValue: "",
    }
  }

  handleSearchInputChange = (newValue) => {
    this.setState({searchValue: newValue});
  }

  handleSearchInputClear = (e) => {
    const { handleSearchInputSubmit } = this.props;

    const newValue = "";
    this.setState({searchValue: newValue});

    handleSearchInputSubmit(newValue);
  }

  handleSubmit = (e) => {
    const { handleSearchInputSubmit } = this.props;
    const { searchValue } = this.state;

    e.preventDefault();
    handleSearchInputSubmit(searchValue);
  }

  render() {
    const { searchValue } = this.state;

    return (
      <div className="SearchBar">
        <form onSubmit={this.handleSubmit}>
          <img src={search_icon} className="icon icon-search" alt="search" width="16" onClick={this.handleSubmit}/>

          <ControlledTextInput
            searchValue={searchValue}
            handleSearchInputChange={this.handleSearchInputChange}
            placeholder="Try `Kraken horizontal yellow`"
          />
          
          <ButtonClose
            searchValue={searchValue}
            onClick={this.handleSearchInputClear}
          />
        </form>
      </div>
    );
  }
}

export default SearchBar;