export const IMAGE_TYPES = [
  {
    label: "Raster",
    value: "raster"
  },
  {
    label: "Vector",
    value: "vector"
  },
];
export const DEFAULT_IMAGE_TYPE = "raster";

export const RASTER_FILE_FORMATS = [
  {
    label: ".jpg",
    value: ".jpg",
  },
  {
    label: ".png",
    value: ".png",
  },
];
export const DEFAULT_RASTER_FILE_FORMAT = ".jpg";

export const RASTER_MIME_TYPES = {
  jpg: "image/jpeg",
  png: "image/png",
}


export const VECTOR_FILE_FORMATS = [
  {
    label: ".svg",
    value: ".svg",
  },
  {
    label: ".pdf",
    value: ".pdf",
  },
];
export const DEFAULT_VECTOR_FILE_FORMAT = ".svg";